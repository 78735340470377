import { Users2, Code2, Share2, Banknote } from 'lucide-react';
  

export function Partners() {
  const areas = [
    {
      title: 'OUTREACH',
      icon: Users2,
      description: 'Building strong connections with communities, corporates, and environmental enthusiasts.'
    },
    {
      title: 'PR',
      icon: Share2,
      description: 'Amplifying our message through compelling campaigns and media engagements.'
    },
    {
      title: 'TECH',
      icon: Code2,
      description: 'Developing digital solutions for biodiversity monitoring and ecosystem protection.'
    },
    {
      title: 'FUNDRAISING',
      icon: Banknote,
      description: 'Securing resources and building lasting donor relationships for ecological impact.'
    }
  ];

  return (
    <div><section id="partners" className="py-20 pt-24 bg-white">
    <div className="container mx-auto">
      <h2 className="text-4xl font-bold text-center mb-4 text-[#3b2317]">Seeking Partners</h2>
      <p className="text-center mb-16 max-w-2xl mx-auto">
        Join us in our mission to restore and protect biodiversity. We're looking for partners across various domains.
      </p>
      <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-8">
        {areas.map((area) => (
          <div 
            key={area.title} 
            className="bg-white rounded-lg shadow-md p-6 text-center hover:shadow-lg transition-shadow duration-300"
          >
            <div className="mb-6">
              <area.icon className="h-12 w-12 mx-auto text-[#3b2317]" />
              <h3 className="text-xl font-semibold mt-4 text-[#3b2317]">{area.title}</h3>
            </div>
            <p className="text-gray-600">{area.description}</p>
          </div>
        ))}
      </div>
    </div>
  </section>
  </div> 
  );
}