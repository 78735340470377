import { Menu, Phone, Mail, MapPin } from 'lucide-react'; 

export function Header() {
  return (
    <header className="bg-[#FFF4E7]/95 backdrop-blur-sm fixed w-full z-50 shadow-sm">
      <div className="container mx-auto">
        <div className="hidden md:flex justify-between py-2 text-sm border-b border-[#3b2317]/10">
          <div className="flex gap-6">
            <a href="mailto:forestryforall@gmail.com" className="flex items-center gap-2 text-[#3b2317]/70 hover:text-[#3b2317]">
              <Mail className="h-4 w-4" />
              forestryforall@gmail.com
            </a>
            <a href="tel:+917019601761" className="flex items-center gap-2 text-[#3b2317]/70 hover:text-[#3b2317]">
              <Phone className="h-4 w-4" />
              +91 70196 01761
            </a>
          </div>
          <div className="flex items-center gap-2 text-[#3b2317]/70">
            <MapPin className="h-4 w-4" />
            #329, 9th main, 4th block, Rajajinagar, Bangalore- 560010
          </div>
        </div>
        <div className="flex items-center justify-between py-4">
          <div className="text-2xl font-bold text-[#3b2317]">FORA - Forestry For All</div>
          <nav className="hidden md:flex items-center gap-8">
            <a href="#about" className="text-[#3b2317]/80 hover:text-[#3b2317]">About Us</a>
            <a href="#team" className="text-[#3b2317]/80 hover:text-[#3b2317]">Team</a>
            <a href="#partners" className="text-[#3b2317]/80 hover:text-[#3b2317]">Partners</a>
            
          </nav>
          
        </div>
      </div>
    </header>
  );
}