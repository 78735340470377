import { Leaf, TreePine, Users } from 'lucide-react';

const pillars = [
  {
    icon: Leaf,
    title: "Ecology and Culture",
    content: (
      <div className="space-y-4">
        <p>
          In our modern consumerist lifestyles, we've lost touch with the ecological significance of our daily practices. Each action we take is interconnected with the larger ecosystem, affecting both individual and community well-being.
        </p>
        <ul className="list-disc list-inside space-y-2">
          <li>Focus on cultural practices like food and clothing</li>
          <li>Understanding the impact of daily choices</li>
          <li>Rebuilding connections with nature</li>
        </ul>
      </div>
    )
  },
  {
    icon: TreePine,
    title: "Traditional Ethno-Botany",
    content: (
      <div className="space-y-4">
        <p>
          India's rich indigenous traditions form a treasure trove of ethno-botanical knowledge, recognized globally through various initiatives.
        </p>
        <ul className="list-disc list-inside space-y-2">
          <li>Integration in Convention on Biological Diversity (CBD)</li>
          <li>Recognition of Traditional Knowledge and Practices</li>
          <li>Preservation of indigenous wisdom</li>
          <li>Bridging traditional practices with modern development</li>
        </ul>
      </div>
    )
  },
  {
    icon: Users,
    title: "Community Engagement",
    content: (
      <div className="space-y-4">
        <p>
          Building bridges between scientific knowledge and traditional practices, we work to create a sustainable future that honors both innovation and ancestral wisdom.
        </p>
        <ul className="list-disc list-inside space-y-2">
          <li>Collaborative conservation efforts</li>
          <li>Knowledge sharing programs</li>
          <li>Local community partnerships</li>
        </ul>
      </div>
    )
  }
];

export function Pillars() {
  return (
    <div>
      <h2 className="pt-24 text-4xl font-bold text-center mb-12 text-[#3b2317]">Pillars of FORA</h2>
      <div className="grid md:grid-cols-3 gap-8">
        {pillars.map((pillar) => (
          <div 
            key={pillar.title} 
            className="bg-white/50 rounded-lg shadow-md p-8 hover:shadow-lg transition-shadow duration-300"
          >
            <div className="mb-6">
              <pillar.icon className="h-12 w-12 text-[#3b2317] mb-4" />
              <h3 className="text-xl font-semibold text-[#3b2317]">{pillar.title}</h3>
            </div>
            <div className="text-gray-600">
              {pillar.content}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}