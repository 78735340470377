export function Team() {
  const team = [
    {
      name: 'Sheshadri Ramaswamy',
      role: 'Founder - Director',
      image: 'https://res.cloudinary.com/dsmfsfyci/image/upload/v1732854598/fora/sesh_hmc65j.jpg',
      bio: 'Forest Ecologist, Biophilic Designer, and Ethnobotanist with extensive experience in biodiversity preservation and ecological restoration.'
    },
   
    {
      name: 'Ramprasad Rajkumar',
      role: 'Director',
      image: 'https://res.cloudinary.com/dsmfsfyci/image/upload/v1732854597/fora/ramprasad_di584z.jpg',
      bio: 'Seasoned professional in administrative and general management, combining technology expertise with passion for nature conservation.'
    },
    {
      name: 'Sujay Dinnalli',
      role: ' Advisor',
      image: 'https://res.cloudinary.com/dsmfsfyci/image/upload/v1732855745/fora/su2jay_vpfx2p.jpg',
      bio: 'General design consultant focused on problems in governance, organisational systems and democratic process. Chief Scientific Officer at Efion.'
    }
  ];

  return (
    <section id="team" className="py-20 bg-gray-50">
      <div className="container mx-auto px-4">
        <h2 className="text-4xl font-bold text-center mb-16 text-[#3b2317]">Meet the Team</h2>
        <div className="grid md:grid-cols-3 gap-8 max-w-6xl mx-auto">
          {team.map((member) => (
            <div key={member.name} className="bg-white rounded-lg shadow-md overflow-hidden hover:shadow-lg transition-shadow duration-300">
              <div className="aspect-w-4 aspect-h-3 relative">
                <img 
                  src={member.image} 
                  alt={member.name} 
                  className="w-full h-48 object-cover object-center"
                />
              </div>
              <div className="p-6">
                <h3 className="text-xl font-semibold text-[#3b2317]">{member.name}</h3>
                <p className="text-emerald-600 mb-4">{member.role}</p>
                <p className="text-gray-600 text-sm leading-relaxed">{member.bio}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}