import "./App.css";

import { Header } from './components/Header'
import { Hero } from "./components/Hero";
 

import { Partners} from "./components/Partners";
import { Pillars } from "./components/Pillars";
import { Team } from "./components/Team";

function App() {
  return (
    <div className="min-h-screen">
    <Header />
    <main>
      <Hero/>
    
      <Pillars/>
      <Team/>
      <Partners />

    </main>
  </div>
  );
}

export default App;


