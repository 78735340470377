export function Hero() {
  return (
    <section className="min-h-screen flex items-center pt-24 bg-[#FFF4E7]">
      <div className="container mx-auto">
        <div className="grid md:grid-cols-2 gap-12 items-center">
          {/* Logo Column */}
          <div className="flex justify-center md:justify-end">
            <div className="p-8 max-w-md">
              <img 
                src="https://res.cloudinary.com/dsmfsfyci/image/upload/v1732906870/fora/logo_tx7ncx.jpg" 
                alt="Forestry For All Foundation" 
                className="w-full h-auto"
              />
            </div>
          </div>

          {/* Text Column */}
          <div className="space-y-6 text-center md:text-left">
            <h1 className="text-5xl md:text-7xl font-bold leading-tight text-[#3b2317]">
              Restoring Nature's Balance
              <span className="block text-3xl md:text-5xl mt-2 text-emerald-600">And OURS</span>
            </h1>
            <p className="text-lg md:text-xl text-gray-700">
              Dedicated to biodiversity protection, conservation and enhancement towards a shared and sustainable future.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}